.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bg {
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  background-color: #f8f9fa;
}

.bg-blue {
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  background-color: rgb(87 108 255);
}

.bg-green {
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  background-color: rgb(44 161 64);
}

.bg-red {
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  background-color: rgb(250, 69, 69);
}

.bg-yellow {
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  background-color: #d4d11c;
}

.bg-etc {
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  background-color: #b9b8ae;
}

.back-to-top {
  position: fixed;
  right: 20px;
  bottom: 20px;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

@font-face {
  font-family: "CookieRun_Bold";
  src: url("./fonts/CookieRun_Bold.ttf");
}

@font-face {
  font-family: "GmarketSansTTFMedium";
  src: url("./fonts/GmarketSansTTFMedium.ttf");
}

.ft-gm {
  font-family: "GmarketSansTTFMedium";
}

.ft-ckr-bold {
  font-family: "CookieRun_Bold";
}

.card-div {
  border-bottom: 1px solid #efe6e6;
}

.card-body {
  margin: 20px;
}